<template>
  <div>
    <top-navigation-component/>
    <div class='p-24'>
      <div class='pha m-auto'>
        <skeleton-loader-component :loading='!order'>
          <payment-section
            v-if='tab === "payment"'
            :order='order'/>
          <result-section
            v-if='tab === "result"'
            :order='order'/>
        </skeleton-loader-component>
      </div>
    </div>
    <bottom-navigation-component/>
  </div>
</template>

<script>
  import BottomNavigation from '@/components/bottom-navigation'
  import SkeletonLoader from '@/components/skeleton-loader'
  import TopNavigation from '@/components/top-navigation'
  import store from '@/store'
  import Payment from './payment'
  import Result from './result'

  export default {
    components: {
      'bottom-navigation-component': BottomNavigation,
      'skeleton-loader-component': SkeletonLoader,
      'top-navigation-component': TopNavigation,
      'payment-section': Payment,
      'result-section': Result
    },

    props: {
      id: String
    },

    data: () => ({
      tab: '',
      order: null
    }),

    watch: {
      order (order, oldOrder) {
        if (order.status === 'awaiting-payment') {
          this.tab = 'payment'
          return
        }

        if (order.status === 'processing') {
          setTimeout(() => {
            this.fetchOrder()
          }, 1000)
        }

        this.tab = 'result'

        if (order.status !== oldOrder?.status) {
          scrollTo(0, 0)
        }
      }
    },

    mounted () {
      this.fetchOrder()
      this.$store.dispatch('me/fetchCards')
    },

    methods: {
      async fetchOrder () {
        let id = this.id
        this.order = await this.$store
          .dispatch('orders/getOrder', { id })
      }
    },

    async beforeRouteEnter (to, from, next) {
      let user = await store.dispatch('auth/getUser')
      if (!user) {
        next({ name: 'login' })
        return
      }
      next()
    }
  }
</script>

<style lang='scss' scoped>
  .pha {
    max-width: 480px;
  }
</style>
