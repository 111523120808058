export default {
  methods: {
    formatDate (value, style) {
      if (!Date.parse(value)) return 'N/A'

      switch (style) {
        case 'gmt': {
          let datePart = new Date(value).toLocaleDateString()
          let timePart = new Date(value).toGMTString().slice(17)
          return `${datePart} ${timePart}`
        }
      }

      return new Date(value).toLocaleDateString()
    },

    formatDistanceToNow (value) {
      if (!Date.parse(value)) return 'N/A'
      return Math.floor((Date.parse(value) - Date.now()) / 86400000)
    }
  }
}
