<template>
  <div>
    <new-credit-card-component class='mt-24'
      @error='showError($event.error)'/>
    <snackbar-element
      ref='successSnackbar'
      :duration='5000'>
      <template v-if='success === "succeeded"'>
        <div class='flex p-16 text-green'>
          <i class='icon mr-16'>check_circle</i>
          <div>{{ $t('pages.order.6ceb2e35') }}</div>
        </div>
      </template>
      <template v-if='success === "processing"'>
        <div class='flex p-16 text-orange'>
          <i class='icon mr-16'>timer</i>
          <div>{{ $t('pages.order.5d88b92f') }}</div>
        </div>
      </template>
    </snackbar-element>
    <snackbar-element
      ref='errorSnackbar'
      :duration='5000'>
      <div class='flex p-16 text-red'>
        <i class='icon mr-16'>error</i>
        <div v-if='error === "requires-payment-method"'>{{ $t('pages.order.b86c04d5') }}</div>
        <div v-if='error === "unknown"'>{{ $t('pages.order.c87d0dd1') }}</div>
      </div>
    </snackbar-element>
  </div>
</template>

<script>
  import NewCreditCard from '@/components/new-credit-card'
  import stripe from '@/stripe'

  export default {
    components: {
      'new-credit-card-component': NewCreditCard
    },

    data: () => ({
      success: '',
      error: ''
    }),

    mounted () {
      this.handleRedirectResult()
    },

    methods: {
      async handleRedirectResult () {
        try {
          let result = await stripe.retrieveSetupIntentResult()

          if (!result) return

          if (result.status === 'succeeded') {
            this.success = 'succeeded'
            this.$refs.successSnackbar.show()
          }

          if (result.status === 'processing') {
            this.success = 'processing'
            this.$refs.successSnackbar.show()
          }

          if (result.status === 'requires_payment_method') {
            this.error = 'requires-payment-method'
            this.$refs.errorSnackbar.show()
          }
        } catch {
          this.error = 'unknown'
          this.$refs.errorSnackbar.show()
        }
      },

      showError (error) {
        this.error = error
        this.$refs.errorSnackbar.show()
      }
    }
  }
</script>
