import anime from 'animejs'

export default {
  methods: {
    scrollTo (target) {
      let html = document.scrollingElement || document.documentElement
      let targetRect = target.getBoundingClientRect()
      let delta = targetRect.top - 0.2 * window.innerHeight
      anime({
        targets: html,
        scrollTop: `+=${delta}`,
        duration: 2 * Math.abs(delta),
        easing: 'easeInOutCubic'
      })
    }
  }
}
