<template>
  <div>
    <card-element>
      <div class='flex'>
        <plan-image-component class='upn overflow-hidden rounded'
          :plan='order.item.data'/>
        <div class='ml-24'>
          <div class='text-xl'>{{ order.item.data.name }}</div>
          <div class='flex mt-16'>
            <i class='icon text-gray-700'>signal_cellular_alt</i>
            <div class='ml-16'>{{ order.item.data.networkType }}</div>
          </div>
        </div>
      </div>
      <div class='mt-24'>
        <div class='flex justify-between'>
          <div class='flex'>
            <i class='icon text-gray-700'>language</i>
            <div class='ml-16'>{{ $t('common.data') }}</div>
          </div>
          <div v-if='order.item.data.data.value > 0'>{{ order.item.data.data.value }} {{ order.item.data.data.unit }}</div>
          <div v-else>{{ $t('common.unlimited') }}</div>
        </div>
        <div class='flex justify-between mt-24'>
          <div class='flex'>
            <i class='icon text-gray-700'>date_range</i>
            <div class='ml-16'>{{ $t('common.validity') }}</div>
          </div>
          <div>{{ order.item.data.period.value }} {{ order.item.data.period.unit }}</div>
        </div>
        <div class='flex justify-between mt-24'>
          <div class='flex'>
            <i class='icon text-gray-700'>attach_money</i>
            <div class='ml-16'>{{ $t('common.price') }}</div>
          </div>
          <div>{{ $n(order.item.data.price.value, { style: 'currency', currency: order.item.data.price.currency, minimumFractionDigits: 2 }) }}</div>
        </div>
        <div class='flex justify-between mt-24'>
          <div class='flex'>
            <i class='icon text-gray-700'>receipt</i>
            <div class='ml-16 whitespace-no-wrap'>{{ $t('common.order-number') }}</div>
          </div>
          <div class='ml-24 truncate'>{{ order.orderNumber }}</div>
        </div>
        <div class='flex justify-between mt-24'>
          <div class='flex'>
            <i class='icon text-gray-700'>event</i>
            <div class='ml-16'>{{ $t('common.purchase-date') }}</div>
          </div>
          <div>{{ formatDate(order.createdDate) }}</div>
        </div>
      </div>
    </card-element>
    <button-element class='is-block is-outlined p-16 mt-24'
      disabled
      v-if='order.status === "processing"'>
      <spinner-element class='mr-16 -ml-16 text-gray-500'/>
      <div>{{ $t('pages.order.1d411fd3') }}</div>
    </button-element>
    <router-link class='block mt-24'
      v-if='order.status === "completed"'
      :to='{ name: "esims" }'>
      <button-element class='is-block p-16'>{{ $t('pages.order.a2824ac9') }}</button-element>
    </router-link>
  </div>
</template>

<script>
  import PlanImage from '@/components/plan-image'
  import dateMixin from '@/mixins/date'

  export default {
    components: {
      'plan-image-component': PlanImage
    },

    mixins: [dateMixin],

    props: {
      order: Object
    }
  }
</script>

<style lang='scss' scoped>
  .upn {
    height: 90px;
  }
</style>
