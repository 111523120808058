<template>
  <div>
    <div class='flex mt-8 text-gray-700'>
      <router-link class='flex'
        :to='{ name: "plan", params: { slug: order.item.data.slug } }'>
        <i class='icon'>arrow_back</i>
      </router-link>
      <div class='ml-16 text-lg font-bold'>{{ $t('pages.order.e97b129e') }}</div>
    </div>
    <skeleton-loader-component class='mt-24'
      :loading='!cards'>
      <div v-if='cards && cards.length'>
        <credit-card-component class='jar rounded transition duration-200 cursor-pointer'
          v-for='card in cards'
          :class='{ "active": selectedCard === card, "inactive": selectedCard && selectedCard !== card }'
          :key='card.id'
          :card='card'
          @click.native='selectCard(card)'/>
      </div>
      <div v-else>
        <div class='my-48 text-center text-gray-500'>{{ $t('pages.order.0d2380a0') }}</div>
      </div>
    </skeleton-loader-component>
    <button-element class='is-block p-16 mt-24'
      ref='payButton'
      v-if='selectedCard'
      :disabled='loading'
      @click.native='payOrder'>
      <spinner-element class='mr-16 -ml-16 text-gray-500'
        v-if='loading'/>
      <div>{{ $t('common.complete-order') }}</div>
    </button-element>
    <new-card-section v-else/>
    <snackbar-element
      ref='errorSnackbar'
      :duration='5000'>
      <div class='flex p-16 text-red'>
        <i class='icon mr-16'>error</i>
        <div v-if='error === "stripe-card-error"'>{{ errorMessage }}</div>
        <div v-if='error === "unknown"'>{{ $t('pages.order.c87d0dd1') }}</div>
      </div>
    </snackbar-element>
  </div>
</template>

<script>
  import CreditCard from '@/components/credit-card'
  import SkeletonLoader from '@/components/skeleton-loader'
  import scrollMixin from '@/mixins/scroll'
  import stripe from '@/stripe'
  import NewCard from './new-card'

  export default {
    components: {
      'credit-card-component': CreditCard,
      'skeleton-loader-component': SkeletonLoader,
      'new-card-section': NewCard
    },

    mixins: [scrollMixin],

    props: {
      order: Object
    },

    data: () => ({
      selectedCard: null,
      error: '',
      errorMessage: '',
      loading: false
    }),

    computed: {
      cards () {
        return this.$store.state.me.cards
      }
    },

    methods: {
      selectCard (card) {
        if (this.selectedCard === card) {
          this.selectedCard = null
        } else {
          this.selectedCard = card
          this.$nextTick(() => {
            this.scrollTo(this.$refs.payButton.$el)
          })
        }
      },

      async payOrder () {
        try {
          this.loading = true

          let id = this.order.id
          let cardId = this.selectedCard.id
          let paymentIntent = await this.$store
            .dispatch('orders/payOrder', { id, cardId })

          await stripe.confirmPayment(paymentIntent.clientSecret, cardId)
          await this.$parent.$parent.fetchOrder()
        } catch (error) {
          if (error.type === 'card_error') {
            this.error = 'stripe-card-error'
            this.errorMessage = error.message
            this.$refs.errorSnackbar.show()
            return
          }

          this.error = 'unknown'
          this.$refs.errorSnackbar.show()
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>

<style lang='scss' scoped>
  .jar {
    &:not(:first-child) {
      @apply mt-16;
    }

    &.active {
      box-shadow: 0 0 0 3px theme('colors.primary.default');
    }

    &.inactive {
      @apply opacity-50;
    }
  }
</style>
